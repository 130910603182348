import React, { useState, useEffect, useRef, ChangeEventHandler } from "react";
import debounce from "lodash/debounce";

// helpers
import { fetchData, processBannerImage } from "helpers/functions";

// components
import Search from "images/Search.svg";

// styles
import styles from "styles/components/core-menu/Search.module.scss";

type Props = {
	setShowSearchBox?: any;
};

const CoreMenuSearch = ({ setShowSearchBox }: Props) => {
    // states
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [resultsProducts, setResultsProducts] = useState<any>(null);
    const [resultsFrameworks, setResultsFrameworks] = useState<any>(null);
    const [resultsGovernance, setResultsGovernance] = useState<any>(null);
    const [products, setProducts] = useState<any>(null);
    const [governance, setGovernance] = useState<any>(null);
	const searchReference: any = useRef(null);
	const inputReference: any = useRef(null);

    // functions
    const getProducts = async () => {
        const res: any = await fetchData(`core-menu-manual/products`);
        if (!res.error && res.data && res.data.data) {
            setProducts(res.data.data);
        }
    };

    const getGovernance = async () => {
        const res: any = await fetchData(`core-menu-manual/governance`);
        if (!res.error && res.data && res.data.data) {
            setGovernance(res.data.data);
			console.log('protocols',res.data.data);
        }
    };

	const searchChange: ChangeEventHandler<HTMLInputElement> = (e: any) => {
		setSearchTerm(e.target.value);
	};
	const debouncedOnSearchChange = debounce(searchChange, 500);

	const submitSearch = () => {
        const searchProducts = products.filter((product: any) => {
            const title = product.title.toLowerCase();
            return title.includes(searchTerm.toLowerCase());
        });
        setResultsProducts(searchProducts);

        // check product extensions
        let matchingExtensions: any = [];
        const searchExtensions = products.filter((product: any) => {
            const productIcon = product.icon;
            const productSlug = product.slug;
            const extension = product.line_extensions_list;
			const guardrails = product.line_extensions_guardrails_menu_title;
			const platform = product.platform_menu_title;
            if (extension && extension.length > 0) {
                extension.forEach((ext: any) => {
                    const extName = ext.attributes.name.toLowerCase();
                    if (extName.includes(searchTerm.toLowerCase())) {
                        matchingExtensions.push({
							key: ext.key,
                            slug: productSlug,
                            name: ext.attributes.name,
                            icon: productIcon,
                        });
                    }
                });
            }
			if (guardrails && guardrails.toLowerCase().includes(searchTerm.toLowerCase())) {
				matchingExtensions.push({
					slug: productSlug + '?guardrails=true',
					name: product.line_extensions_guardrails_menu_title,
					icon: productIcon,
				});
			}
			if (platform && platform.toLowerCase().includes(searchTerm.toLowerCase())) {
				matchingExtensions.push({
					slug: productSlug + '?platform=true',
					name: product.platform_menu_title,
					icon: productIcon,
				});
			}
        });
        setResultsFrameworks(matchingExtensions);

        // check governance
        const searchGovernance = governance.filter((item: any) => {
            const title = item.title.toLowerCase();
            return title.includes(searchTerm.toLowerCase());
        });
        setResultsGovernance(searchGovernance);
    };

	const useOutsideAlerter = (ref: any) => {
		useEffect(() => {
		  const handleClickOutside = (event: any) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setShowSearchBox(false);
			}
		  }
		  document.addEventListener("mousedown", handleClickOutside);
		  return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		  };
		}, [ref]);
	  }

	  useOutsideAlerter(searchReference);

    // do stuff
    useEffect(() => {
        getProducts();
        getGovernance();
		inputReference.current.focus();
    }, []);

	useEffect(() => {
		if (products && searchTerm) {
			submitSearch();
		}
	}, [searchTerm])

    return (
		<>
			<div className={styles.searchOverlay}></div>
			<div className={styles.searchBox} ref={searchReference}>
				<form className={styles.searchForm}>
					<label htmlFor={"nav-search"} className="sr-only">
						Search
					</label>
					<input
						ref={inputReference}
						id={"nav-search"}
						type={"text"}
						placeholder={"Search"}
						defaultValue={searchTerm}
						className={styles.searchInput}
						onChange={debouncedOnSearchChange}
					/>
					<button
						className={styles.searchSubmit}
						aria-label="submit search"
						type="submit">
						<Search />
					</button>
				</form>
				{searchTerm &&
					<div className={styles.searchResults}>
						<div>
							{resultsProducts && resultsProducts.length > 0 && (
								<>
									<p className={styles.searchResultsHead}>Products</p>
									<div className={styles.searchResultsList}>
										{resultsProducts.map(
											(result: any, index: any) => {
												return (
													<a
														href={`/products/${result.slug}`}
														key={index}
														className={
															styles.searchResultsItem
														}>
														<div
															className={
																styles.searchResultsIcon
															}>
																
															<img
																src={processBannerImage(result.icon, true)}
																alt=""
															/>
														</div>
														<p
															className={
																styles.searchResultsTitle
															}>
															{result.title}
														</p>
													</a>
												);
											}
										)}
									</div>
								</>
							)}

							{resultsFrameworks && resultsFrameworks.length > 0 && (
								<>
									<p className={styles.searchResultsHead}>
										Frameworks
									</p>
									<div className={styles.searchResultsList}>
										{resultsFrameworks.map(
											(result: any, index: any) => {
												console.log('result', result);
												return (
													<a
														href={result.key ? `/products/${result.slug}?extKey=${result.key}` : `/products/${result.slug}`}
														key={index}
														className={
															styles.searchResultsItem
														}>
														<div
															className={
																styles.searchResultsIcon
															}>
															<img
																src={processBannerImage(result.icon, true)}
																alt=""
															/>
														</div>
														<p
															className={
																styles.searchResultsTitle
															}>
															{result.name}
														</p>
													</a>
												);
											}
										)}
									</div>
								</>
							)}

							{resultsGovernance && resultsGovernance.length > 0 && (
								<>
									<p className={styles.searchResultsHead}>
										Protocols and Approvals
									</p>
									<div className={styles.searchResultsList}>
										{resultsGovernance.map(
											(result: any, index: any) => {
												return (
													<a
														href={`/${result.slug}`}
														key={index}
														className={
															styles.searchResultsItem
														}>
														{result.banner_image &&
															<div
																className={
																	styles.searchResultsIcon
																}>
																	<img
																		src={processBannerImage(result.banner_image, true)
																		}
																		alt=""
																	/>
															</div>
														}

														<p
															className={
																styles.searchResultsTitle
															}>
															{result.title}
														</p>
													</a>
												);
											}
										)}
									</div>
								</>
							)}

							{resultsProducts && resultsProducts.length < 1 && resultsFrameworks && resultsFrameworks.length < 1 && resultsGovernance && resultsGovernance.length < 1 &&
								<p>Sorry, no results found.</p>
							}
						</div>
					</div>
				}
			</div>
		</>
    );
};

export default CoreMenuSearch;